import Link from "next/link";
import { useRouter } from "next/router";
import React, { ReactNode } from "react";
import { appendNextLinkHrefParams } from "~/src/utils/nextLinkHref";

interface IChevronLinkProps {
  children: ReactNode;
  projectSlug: string;
}

export default function ChevronLink({ children, projectSlug }: IChevronLinkProps) {
  const router = useRouter();

  return (
    <Link
      href={appendNextLinkHrefParams(router, {
        projectSlug,
      })}
      as={`/projects/${projectSlug}`}
      passHref={true}
      scroll={false}
    >
      <a
        onClick={event => event.stopPropagation()}
        css={{
          display: "block",
          padding: 20,
        }}
      >
        {children}
      </a>
    </Link>
  );
}
