import { gql } from "@apollo/client";
import GroupProjectFields from "~/lib/queries/fragments/GroupProjectFields";
import GovernmentProjectFields from "~/lib/queries/fragments/GovernmentProjectFields";
import VendorProjectFields from "~/lib/queries/fragments/VendorProjectFields";

export default gql`
  mutation CreateProjectForVendor($project: ProjectInput!, $vendor: ObjectId!) {
    createProjectForVendor(project: $project, vendor: $vendor) {
      _id
      ...GroupProjectFields
      ...GovernmentProjectFields
      ...VendorProjectFields
    }
  }

  ${GroupProjectFields}
  ${GovernmentProjectFields}
  ${VendorProjectFields}
`;
