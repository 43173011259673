import { useQuery } from "@apollo/client";
import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@govlaunch/web";
import React, { useEffect, useRef } from "react";
import { useSelfie } from "~/components/auth/Auth";
import { findLanguageByLocale } from "~/intl/locales";
import { useIntlLocale } from "~/lib/withIntl";
import { IProject } from "~/types/types";
import TranslateTo from "~/src/components/translation/TranslateTo";
import ProjectBySlugQuery from "~/src/components/project/graphql/ProjectBySlugQuery";
import {
  IProjectBySlugQuery,
  IProjectBySlugQueryVariables,
} from "~/src/components/project/graphql/__generated__/ProjectBySlugQuery.generated";
import ProjectIsPendingVerification from "~/src/components/project/ProjectIsPendingVerification";
import ProjectWithActions from "~/src/components/project/ProjectWithActions";
import ProjectModalNavigation from "~/src/components/project/modal/ProjectModalNavigation";

export interface INavigation {
  previousProjectSlug?: string;
  nextProjectSlug?: string;
}

interface IProjectModalProps {
  projectSlug: string;
  navigation?: INavigation;
  onClose?: () => void;
  onClickProject?: (project: Pick<IProject, "_id" | "slug">) => void;
  lang?: string;
}

export default function ProjectModal({ projectSlug, onClose, onClickProject, navigation, lang }: IProjectModalProps) {
  const langExists = typeof lang !== "undefined" && lang.length > 0 && lang !== "en-US";
  const { locale } = useIntlLocale();
  const targetLanguage = lang || locale;
  const showTranslateButton = langExists || locale !== "en-US";

  const { data, loading, refetch } = useQuery<IProjectBySlugQuery, IProjectBySlugQueryVariables>(ProjectBySlugQuery, {
    variables: {
      slug: projectSlug,
      token: null,
      language: findLanguageByLocale(lang),
    },
    fetchPolicy: "network-only",
  });
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const viewer = useSelfie();
  const showMakersUpgrade = viewer && viewer.__typename === "VendorUser" && !data?.projectBySlug?.viewerCanView;

  useEffect(() => {
    overlayRef.current?.scrollTo(0, 0);
  }, [projectSlug]);

  const translateToButton = () => {
    return (
      <TranslateTo
        langExists={langExists}
        showTranslateButton={showTranslateButton}
        refetch={() =>
          refetch({
            slug: projectSlug,
            token: null,
            language: findLanguageByLocale(targetLanguage),
          })
        }
        refetchOriginal={() =>
          refetch({
            slug: projectSlug,
            token: null,
            language: null,
          })
        }
      />
    );
  };

  const isPendingVerification =
    data?.projectBySlug &&
    data.projectBySlug.__typename === "VendorProject" &&
    data.projectBySlug.locations.length === 0 &&
    data.projectBySlug.locationsPendingVerification.length > 0;

  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      size="800px"
      blockScrollOnMount={true}
      scrollBehavior="outside"
      closeOnEsc={true}
      closeOnOverlayClick={true}
    >
      <ModalOverlay ref={overlayRef} />

      <ModalContent borderRadius="md" ref={overlayRef}>
        {navigation && !showMakersUpgrade && <ProjectModalNavigation navigation={navigation} />}

        <ModalCloseButton
          top={-43}
          right="4px"
          size="xl"
          _hover={{
            bg: "transparent",
          }}
          color="#FFF"
        />

        {isPendingVerification && (
          <Box position="relative" mb={4}>
            <ProjectIsPendingVerification />
          </Box>
        )}

        <ModalBody
          pt={8}
          pb={4}
          px={[1, 2, 6]}
          borderRadius="md"
          borderBottomWidth={showMakersUpgrade ? 15 : 22}
          borderBottomColor={showMakersUpgrade ? "white" : "blue.600"}
          onClick={(event: { stopPropagation: () => any }) => event.stopPropagation()}
        >
          {isPendingVerification && <Box height={[4, 4, 0, 0]} />}
          <ProjectWithActions
            loading={loading}
            onClickProject={onClickProject}
            project={data?.projectBySlug || null}
            onVerify={() => {
              if (onClose) {
                onClose();
              }
            }}
            onDeleteProject={() => {
              if (onClose) {
                onClose();
              }
            }}
            translationButton={translateToButton}
            isInModal={true}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
