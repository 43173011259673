import { IProjectAudience } from "~/types/types";
import { IProjectFormValues } from "@govlaunch/project/src/form/types";
import { ISelfieQuery } from "~/lib/queries/__generated__/SelfieQuery.generated";

export default function getSelectableAudiences(
  values: IProjectFormValues,
  isWithinGroup: boolean,
  viewer: NonNullable<ISelfieQuery["selfie"]>,
) {
  const hasSelectedGovernments = values?.locations.length > 0;

  if (isWithinGroup && !hasSelectedGovernments) {
    return [IProjectAudience.Private];
  }

  if (viewer.__typename === "VendorUser" && !hasSelectedGovernments) {
    return [IProjectAudience.Private];
  }

  return [IProjectAudience.Public, IProjectAudience.Community, IProjectAudience.Private];
}
