import React from "react";
import * as palette from "@govlaunch/palette";
import { Margin } from "~/components/spacings";
import useIsMobile from "~/lib/hooks/useIsMobile";
import Link from "next/link";
import { useIntl } from "react-intl";

interface IEmptyProjectSlot {
  onClick: () => any;
  groupId?: any;
  text?: string;
}

export default function EmptyProjectSlot({ onClick, groupId, text }: IEmptyProjectSlot) {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const textOrIntl = text || intl.formatMessage({ defaultMessage: 'Add another project', id: 'B0aG5V' });

  if (isMobile) {
    return <EmptyProjectSlotMobile groupId={groupId} text={textOrIntl} />;
  }

  return <EmptyProjectSlotContent onClick={onClick} text={textOrIntl} />;
}

interface IEmptyProjectSlotMobileProps {
  groupId?: any;
  text?: string;
}

function EmptyProjectSlotMobile({ groupId, text }: IEmptyProjectSlotMobileProps) {
  return (
    <Link href={`/projects/create?groupId=${groupId}`} as="/projects/create" passHref={true}>
      <EmptyProjectSlotContent text={text} />
    </Link>
  );
}

interface IEmptyProjectSlotContentProps {
  onClick?: () => any;
  text?: string;
}

function EmptyProjectSlotContent({ text, onClick }: IEmptyProjectSlotContentProps) {
  return (
    <div
      data-intercom-target="Government Add Project"
      role="button"
      aria-label={text}
      css={{
        border: `dashed 1px ${palette.innovatorBlue}`,
        backgroundColor: "rgba(0, 90, 238, 0.03)",
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        minHeight: 200,
        cursor: "pointer",
        "&:hover": {
          opacity: 0.7,
        },
      }}
      onClick={onClick}
    >
      <div
        css={{
          width: 46,
          height: 46,
          border: `dashed 1px ${palette.innovatorBlue}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "100%",
        }}
      >
        <svg width={22} height={22}>
          <path
            fill="#005AEE"
            fillRule="evenodd"
            d="M20.894 10.06h-9.286V.774a.773.773 0 1 0-1.548 0v9.286H.774a.773.773 0 1 0 0 1.548h9.286v9.286a.773.773 0 1 0 1.548 0v-9.286h9.286a.773.773 0 1 0 0-1.548z"
          />
        </svg>
      </div>

      <Margin mt={8}>
        <span
          css={{
            fontSize: 14,
            fontWeight: 600,
            letterSpacing: 0.1,
            color: palette.innovatorBlue,
          }}
        >
          {text}
        </span>
      </Margin>
    </div>
  );
}
