import { gql } from "@apollo/client";
import TagFields from "~/lib/queries/fragments/TagFields";

export default gql`
  fragment VendorProjectFields on VendorProject {
    _id
    icon
    title
    slug
    tags {
      _id
      ...TagFields
    }
    awards {
      _id
      slug
      name
    }
    description
    subtitle
    status
    createdAt
    updatedAt
    viewerIsAuthor
    viewerCanDelete
    viewerDidUpvote
    viewerDidComment
    viewerDidBookmark
    viewerCanInviteCollaborators
    viewerCanRemoveCollaborators
    viewerCanComment
    commentsCount
    upvotesCount

    outcome
    outcomeStatement
    vendors {
      _id
      logo
      name
      description
      slug
    }
    groups {
      _id
      name
      logo
    }
    itemsCount {
      products
      newsworthy
      attachments
      locations
      links
      vendors
    }
    viewerCanEdit
    viewerCanRead
    audience
    outsideProducts {
      _id
      name
      url
    }
    attachments {
      title
      url
      mimeType
      size
    }
    newsworthy {
      title
      siteName
      favicon
      url
      description
    }
    links {
      title
      displayTitle
      description
      url
      siteName
      favicon
    }
    products {
      _id
      logo
      name
      description
      slug
    }
    vendor {
      _id
      slug
      logo
      name
      description
      viewerIsMember
    }
    locations {
      _id
      slug
      name
      logo
      city {
        _id
        name
        state(full: true)
        governmentType
      }
    }
    locationsPendingVerification {
      _id
      slug
      name
      logo
      viewerIsMember
      city {
        _id
        name
        state(full: true)
        governmentType
      }
    }
    collaborators {
      ... on GovernmentUser {
        _id
        slug
        fullName
        avatar
        jobTitle
        government {
          _id
          name
        }
      }
      ... on VendorUser {
        _id
        slug
        fullName
        avatar
        jobTitle
        company {
          _id
          name
        }
      }
      ... on PendingUser {
        _id
        slug
        fullName
        avatar
        jobTitle
      }
    }
    author {
      ... on GovernmentUser {
        _id
        slug
        email
        fullName
        avatar
        jobTitle
        isSiteAdmin
        isLimitedSiteAdmin
        government {
          _id
          slug
          name
          logo
          city {
            _id
            country
            countryName
          }
        }
      }
      ... on VendorUser {
        _id
        slug
        email
        fullName
        avatar
        jobTitle
        isSiteAdmin
        isLimitedSiteAdmin
        company {
          _id
          slug
          name
          logo
        }
      }
      ... on PendingUser {
        _id
        slug
        email
        fullName
        avatar
        jobTitle
        isSiteAdmin
        isLimitedSiteAdmin
      }
    }
  }

  ${TagFields}
`;
