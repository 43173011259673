import React from "react";

export function PreviousChevronIcon() {
  return (
    <svg width="14" height="39" viewBox="0 0 14 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.1333 36.7058L1.86666 19.9411L12.1333 2.29404"
        stroke="#FAFAF9"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function NextChevronIcon() {
  return (
    <svg width="14" height="39" viewBox="0 0 14 39" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.86667 36.7058L12.1333 19.9411L1.86667 2.29404"
        stroke="#FAFAF9"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
