import { gql } from "@apollo/client";
import GroupProjectFields from "~/lib/queries/fragments/GroupProjectFields";
import GovernmentProjectFields from "~/lib/queries/fragments/GovernmentProjectFields";

export default gql`
  mutation CreateProjectForGroup($project: ProjectInput!, $group: ObjectId!) {
    createProjectForGroup(project: $project, group: $group) {
      _id
      ...GroupProjectFields
      ...GovernmentProjectFields
    }
  }

  ${GroupProjectFields}
  ${GovernmentProjectFields}
`;
