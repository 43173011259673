import { Box } from "@govlaunch/web";
import { useRouter } from "next/router";
import React from "react";
import stringOrFirst from "~/src/utils/stringOrFirst";
import KeyboardProjectsNavigation from "~/src/components/project/modal/KeyboardProjectsNavigation";
import { INavigation } from "~/src/components/project/modal/ProjectModal";
import ChevronLink from "~/src/components/project/modal/ChevronLink";
import { PreviousChevronIcon, NextChevronIcon } from "~/src/components/project/modal/icons";

interface IProjectModalNavigationProps {
  navigation: INavigation;
}

export default function ProjectModalNavigation({ navigation }: IProjectModalNavigationProps) {
  const router = useRouter();

  return (
    <>
      <KeyboardProjectsNavigation key={stringOrFirst(router.query.projectSlug)} navigation={navigation} />

      {navigation.previousProjectSlug && (
        <Box position="absolute" top="calc(50% - 35px)" left={0} ml={-52}>
          <ChevronLink projectSlug={navigation.previousProjectSlug}>
            <PreviousChevronIcon />
          </ChevronLink>
        </Box>
      )}

      {navigation.nextProjectSlug && (
        <Box position="absolute" top="calc(50% - 35px)" right={0} mr={-52}>
          <ChevronLink projectSlug={navigation.nextProjectSlug}>
            <NextChevronIcon />
          </ChevronLink>
        </Box>
      )}
    </>
  );
}
