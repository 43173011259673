import { gql } from "@apollo/client";
import GroupProjectFields from "~/lib/queries/fragments/GroupProjectFields";
import GovernmentProjectFields from "~/lib/queries/fragments/GovernmentProjectFields";

export default gql`
  mutation CreateProjectForGovernment($project: ProjectInput!, $government: ObjectId!) {
    createProjectForGovernment(project: $project, government: $government) {
      _id
      ...GroupProjectFields
      ...GovernmentProjectFields
    }
  }

  ${GroupProjectFields}
  ${GovernmentProjectFields}
`;
