import { IProjectCardFieldsFragment } from "@govlaunch/project";

export default function computeNavigationProps(projects: IProjectCardFieldsFragment[], projectSlug: string) {
  const projectSlugs = projects.map(project => project.slug);

  if (projectSlugs.length === 1) {
    return {
      previousProjectSlug: undefined,
      nextProjectSlug: undefined,
    };
  }

  const currentProjectSlugIndex = projectSlugs.indexOf(projectSlug);
  const previousProjectSlug = projectSlugs[currentProjectSlugIndex - 1];
  const nextProjectSlug = projectSlugs[currentProjectSlugIndex + 1];

  return {
    previousProjectSlug,
    nextProjectSlug,
  };
}
