import { useEffect } from "react";
import { INavigation } from "~/src/components/project/modal/ProjectModal";
import { useRouter } from "next/router";
import { appendNextLinkHrefParams } from "~/src/utils/nextLinkHref";

interface IKeyboardProjectsNavigationProps {
  navigation: INavigation;
}

export default function KeyboardProjectsNavigation({ navigation }: IKeyboardProjectsNavigationProps) {
  const router = useRouter();

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === "ArrowLeft" && navigation.previousProjectSlug) {
        router.push(
          appendNextLinkHrefParams(router, {
            projectSlug: navigation.previousProjectSlug,
          }),
          `/projects/${navigation.previousProjectSlug}`,
        );
      }

      if (event.key === "ArrowRight" && navigation.nextProjectSlug) {
        router.push(
          appendNextLinkHrefParams(router, {
            projectSlug: navigation.nextProjectSlug,
          }),
          `/projects/${navigation.nextProjectSlug}`,
        );
      }
    };

    window.addEventListener("keyup", handleKeyUp);
    return () => window.removeEventListener("keyup", handleKeyUp);
  }, []);

  return null;
}
