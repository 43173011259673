import { useApolloClient, useMutation } from "@apollo/client";
import { ProjectForm } from "@govlaunch/project";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@govlaunch/web";
import { useRouter } from "next/router";
import React from "react";
import { useSelfie } from "~/components/auth/Auth";
import CreateProjectForGovernment from "~/lib/mutations/CreateProjectForGovernment";
import CreateProjectForGroup from "~/lib/mutations/CreateProjectForGroup";
import CreateProjectForVendor from "~/lib/mutations/CreateProjectForVendor";
import {
  ICreateProjectForGovernmentMutation,
  ICreateProjectForGovernmentMutationVariables,
} from "~/lib/mutations/__generated__/CreateProjectForGovernment.generated";
import {
  ICreateProjectForGroupMutation,
  ICreateProjectForGroupMutationVariables,
} from "~/lib/mutations/__generated__/CreateProjectForGroup.generated";
import {
  ICreateProjectForVendorMutation,
  ICreateProjectForVendorMutationVariables,
} from "~/lib/mutations/__generated__/CreateProjectForVendor.generated";
import nextLinkHref from "~/src/utils/nextLinkHref";
import { refetchQueries } from "~/lib/apollo/RefetchQueriesFixLink";
import { FORM_ERROR } from "final-form";
import { GroupsAutoComplete } from "@govlaunch/project/src/form/autoComplete";
import {
  IAutoCompleteGroupsQuery,
  IAutoCompleteGroupsQueryVariables,
} from "~/lib/queries/__generated__/AutoCompleteGroups.generated";
import AutoCompleteGroups from "~/lib/queries/AutoCompleteGroups";
import getSelectableAudiences from "~/src/components/project/getSelectableAudiences";
import { IGroupKind } from "~/types/types";
import { useIntl } from "react-intl";

interface ICreateProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
  groupId?: string;
}

export default function CreateProjectModal({ isOpen, onClose, groupId }: ICreateProjectModalProps) {
  const viewer = useSelfie();
  const router = useRouter();
  const apolloClient = useApolloClient();
  const intl = useIntl();

  const maxProjects = intl.formatMessage({
    defaultMessage: `Your current plan doesn't support more projects.`,
    id: "Mx4aKt",
  });

  const [createProjectForVendor] = useMutation<
    ICreateProjectForVendorMutation,
    ICreateProjectForVendorMutationVariables
  >(CreateProjectForVendor);

  const [createProjectForGovernment] = useMutation<
    ICreateProjectForGovernmentMutation,
    ICreateProjectForGovernmentMutationVariables
  >(CreateProjectForGovernment);

  const [createProjectForGroup] = useMutation<ICreateProjectForGroupMutation, ICreateProjectForGroupMutationVariables>(
    CreateProjectForGroup,
  );

  const { Provider: GroupsProvider } = GroupsAutoComplete;

  if (!viewer) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />

      <ModalContent borderRadius="md">
        <ModalCloseButton top={-43} right="4px" size="xl" _hover={{ bg: "transparent" }} color="#FFF" />

        <ModalBody px={6} py={4}>
          <GroupsProvider
            onSearch={async (query) => {
              const queryData = await apolloClient.query<IAutoCompleteGroupsQuery, IAutoCompleteGroupsQueryVariables>({
                query: AutoCompleteGroups,
                variables: {
                  searchQuery: query,
                  groupKinds: [IGroupKind.Vendor, IGroupKind.Community],
                  limit: 8,
                },
              });

              return queryData.data.autoCompleteGroups || [];
            }}
          >
            <ProjectForm
              isVendorProject={viewer.__typename === "VendorUser"}
              getSelectableAudienceOptions={(values) => getSelectableAudiences(values, !!groupId, viewer)}
              onSubmit={async (values) => {
                if (groupId) {
                  const result = await createProjectForGroup({
                    variables: {
                      project: values,
                      group: groupId,
                    },
                  });

                  if (result.data && result.data.createProjectForGroup.__typename === "GroupProject") {
                    const projectSlug = result.data.createProjectForGroup.slug;

                    const link = nextLinkHref("/projects/[projectSlug]", {
                      projectSlug,
                    });

                    router.push(link, `/projects/${projectSlug}`);
                  }

                  return;
                }

                if (viewer.__typename === "VendorUser") {
                  try {
                    const result = await createProjectForVendor({
                      variables: {
                        project: values,
                        vendor: viewer.company?._id,
                      },
                      refetchQueries: refetchQueries(["CompanyAccountBillingInfo"]),
                    });

                    if (result.data) {
                      const projectSlug = result.data.createProjectForVendor.slug;

                      const link = nextLinkHref("/projects/[projectSlug]", {
                        projectSlug,
                      });

                      router.push(link, `/projects/${projectSlug}`);
                    }
                  } catch {
                    return {
                      [FORM_ERROR]: maxProjects,
                    };
                  }
                }

                if (viewer.__typename === "GovernmentUser") {
                  const result = await createProjectForGovernment({
                    variables: {
                      project: values,
                      government: viewer.government._id,
                    },
                  });

                  if (result.data?.createProjectForGovernment.__typename === "GovernmentProject") {
                    const projectSlug = result.data.createProjectForGovernment.slug;

                    const link = nextLinkHref("/projects/[projectSlug]", {
                      projectSlug,
                    });

                    router.push(link, `/projects/${projectSlug}`);
                  }
                }
              }}
            />
          </GroupsProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
