import { gql } from "@apollo/client";

export default gql`
  query AutoCompleteGroups($searchQuery: String!, $groupKinds: [String!], $limit: Int!) {
    autoCompleteGroups(searchQuery: $searchQuery, groupKinds: $groupKinds, limit: $limit) {
      _id
      name
      slug
      logo
    }
  }
`;
